import React from 'react';
import PropTypes from 'prop-types';

const Bookmark = () => (
	<></>
);

Bookmark.propTypes = {
	title: PropTypes.string.isRequired, // eslint-disable-line
	children: PropTypes.node.isRequired // eslint-disable-line
};

export default Bookmark;
