import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Topbar from '../Topbar';
import {Notifications} from '../Other';

const Layout = (props) => {
	const {
		active,
		children,
		size
	} = props;

	return (
		<>
			<Topbar active={active} />
			<Notifications />
			{
				children && (
					<div className="scroll-container">
						<div className={classNames('container', size)}>
							{children}
						</div>
					</div>
				)
			}
		</>
	);
};

Layout.propTypes = {
	active: PropTypes.string,
	children: PropTypes.node,
	size: PropTypes.oneOf(['ultra-thin', 'thin', 'regular'])
};

Layout.defaultProps = {
	active: null,
	children: null,
	size: 'regular'
};

export default Layout;
