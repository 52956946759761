import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import Layout from '../../components/Layout';

class Verify extends Component {
	componentDidMount() {
		this.verify();
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.verified && this.props.verified) {
			this.props.addNotification('success', 'Konto zweryfikowane', 'Możesz teraz zalogować się i korzystać z naszej aplikacji.');
			this.props.history.push('/');
		}

		if (!prevProps.error && this.props.error) {
			this.props.history.push('/');
		}
	}

	verify = () => {
		const {
			code,
			verify
		} = this.props;

		verify(code);
	}

	render() {
		return (
			<Layout />
		);
	}
}

Verify.propTypes = {
	history: PropTypes.object.isRequired,
	error: PropTypes.bool.isRequired,
	code: PropTypes.string.isRequired,
	verify: PropTypes.func.isRequired,
	verified: PropTypes.bool.isRequired,
	addNotification: PropTypes.func.isRequired
};

export default withRouter(Verify);
