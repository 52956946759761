import React from 'react';

import Layout from '../../components/Layout';

const Knowledge = () => (
	<Layout active="knowledge">
		<i />
	</Layout>
);

export default Knowledge;
