import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactDOM from 'react-dom';

const Loader = (props) => {
	const {
		type,
		text
	} = props;

	if ((['full']).includes(type)) {
		return ReactDOM.createPortal((
			<div className={classNames('loader', type)}>
				<div>
					<div className="line" />
					<p><small>{text}</small></p>
				</div>
			</div>
		), document.getElementById('modals'));
	}

	return (
		<div className={classNames('loader', type)}>
			<div>
				<div className="line" />
				<p><small>{text}</small></p>
			</div>
		</div>
	);
};

Loader.propTypes = {
	type: PropTypes.oneOf(['full', 'box']),
	text: PropTypes.string
};

Loader.defaultProps = {
	type: 'full',
	text: 'ładowanie...'
};

export default Loader;
