import React, {Component} from 'react';
import {Tab, Tabs} from '../Other';

class Talk extends Component {
	state = {
		tab: null
	}

	handleChange = (tab) => {
		this.setState({
			tab
		});
	}

	render() {
		const {tab} = this.state;

		return (
			<>
				<Tabs active={tab} handleChange={this.handleChange}>
					<Tab
						label="rozmawiaj teraz"
						text="aby wybrać psychologa dostępnego online (7:00-22:00)"
					>
						<p>panel rozmowy</p>
					</Tab>
					<Tab
						label="umów rozmowę"
						text="aby wybrać psychologa lub dogodny dla Ciebie termin"
					>
						<p>panel umawiania się</p>
					</Tab>
				</Tabs>
			</>
		);
	}
}

export default Talk;
