import axios from 'axios';
import {MyAccount} from '../util/actionTypes';

export const getAccount = () => (dispatch) => {
	dispatch({
		type: MyAccount.Get.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/account/my`)
		.then((payload) => {
			if (payload.success) {
				dispatch({
					type: MyAccount.Get.SUCCESS,
					account: payload.message
				});
			} else {
				dispatch({
					type: MyAccount.Get.FAILURE
				});
			}
		})
		.catch(() => {
			dispatch({
				type: MyAccount.Get.FAILURE
			});
		});
};
