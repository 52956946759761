import {connect} from 'react-redux';

import Contact from './Contact';
import {formSubmit} from '../../actions/contact';
import {addNotification} from '../../actions/notification';

const mapStateToProps = (state) => ({
	formSubmitted: state.contact.formSubmitted,
	loading: state.contact.status.loading
});

const mapDispatchToProps = (dispatch) => ({
	formSubmit: (data) => dispatch(formSubmit(data)),
	addNotification: (type, title, message) => dispatch(addNotification(type, title, message))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Contact);
