import {MyAccount} from '../util/actionTypes';
import {DEFAULT_STATUS} from '../util/types';

const initialState = {
	account: null
};

const myAccount = (state = initialState, action) => {
	switch (action.type) {
		case MyAccount.Get.REQUEST:
			return {
				...state,
				account: null,
				status: {
					loading: true,
					error: false
				}
			};
		case MyAccount.Get.SUCCESS:
			return {
				...state,
				account: action.account,
				status: DEFAULT_STATUS
			};
		case MyAccount.Get.FAILURE:
			return {
				...state,
				status: {
					loading: false,
					error: true
				}
			};
		default:
			return state;
	}
};

export default myAccount;
