import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Form extends Component {
	handleSubmit = (e) => {
		e.preventDefault();

		return false;
	}

	render() {
		const {children} = this.props;

		return (
			<form onSubmit={this.handleSubmit}>
				{children}
			</form>
		);
	}
}

Form.propTypes = {
	children: PropTypes.node.isRequired
};

export default Form;
