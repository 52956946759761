import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const MainItem = (props) => {
	const {to, name, active} = props;

	return (
		<Link
			className={classNames({active})}
			to={to}
		>
			{name}
		</Link>
	);
};

MainItem.propTypes = {
	name: PropTypes.string.isRequired,
	to: PropTypes.string.isRequired,
	active: PropTypes.bool.isRequired
};

export default MainItem;
