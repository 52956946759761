import React from 'react';
import PropTypes from 'prop-types';

const Book = () => (
	<></>
);

Book.propTypes = {
	title: PropTypes.string.isRequired, // eslint-disable-line
	children: PropTypes.node.isRequired, // eslint-disable-line
	withBookmarks: PropTypes.bool // eslint-disable-line
};

Book.defaultProps = {
	withBookmarks: false
};

export default Book;
