import {connect} from 'react-redux';

import Notifications from './Notifications';

const mapStateToProps = (state) => ({
	notifications: state.notification.notifications
});

export default connect(
	mapStateToProps
)(Notifications);
