import {connect} from 'react-redux';

import Verify from './Verify';
import {verify} from '../../actions/authorization';
import {addNotification} from '../../actions/notification';

const mapStateToProps = (state, ownProps) => ({
	code: ownProps.match.params.code,
	verified: state.authorization.verified,
	error: state.authorization.status.error
});

const mapDispatchToProps = (dispatch) => ({
	verify: (code) => dispatch(verify(code)),
	addNotification: (type, title, message) => dispatch(addNotification(type, title, message))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Verify);
