export const StepType = {
	SIGNIN: 'SIGNIN',
	REQUEST_RESET_PASSWORD: 'REQUEST_RESET_PASSWORD',
	REQUEST_RESET_PASSWORD_CONFIRM: 'REQUEST_RESET_PASSWORD_CONFIRM',
	RESET_PASSWORD: 'RESET_PASSWORD',
	RESET_PASSWORD_CONFIRM: 'RESET_PASSWORD_CONFIRM',
	SIGNUP_CHOOSE: 'SIGNUP_CHOOSE',
	SIGNUP_AS_THERAPIST: 'SIGNUP_AS_THERAPIST',
	SIGNUP_AS_CLIENT: 'SIGNUP_AS_CLIENT',
	SIGNUP_CONFIRM: 'SIGNUP_CONFIRM'
};

export const DEFAULT_STATUS = {
	loading: false,
	error: false
};

export const AccountTypeName = {
	THERAPIST: 'THERAPIST',
	CLIENT: 'CLIENT'
};
