import {connect} from 'react-redux';

import Topbar from './Topbar';

const mapStateToProps = (state, ownProps) => ({
	...ownProps
});

export default connect(
	mapStateToProps
)(Topbar);
