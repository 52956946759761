import {combineReducers} from 'redux';

import authorization from './authorization';
import contact from './contact';
import notification from './notification';
import myAccount from './myAccount';

export default combineReducers({
	authorization,
	contact,
	notification,
	profile: myAccount
});
