import {DEFAULT_STATUS} from '../util/types';
import {Contact} from '../util/actionTypes';

const initialState = {
	formSubmitted: false,
	status: DEFAULT_STATUS
};

const contact = (state = initialState, action) => {
	switch (action.type) {
		case Contact.FormSubmit.REQUEST:
			return {
				...state,
				formSubmitted: false,
				status: {
					loading: true,
					error: false
				}
			};
		case Contact.FormSubmit.SUCCESS:
			return {
				...state,
				formSubmitted: true,
				status: DEFAULT_STATUS
			};
		case Contact.FormSubmit.FAILURE:
			return {
				...state,
				status: {
					loading: false,
					error: true
				}
			};
		default:
			return state;
	}
};

export default contact;
