import axios from 'axios';

import {Authorization} from '../util/actionTypes';
import {addNotification} from './notification';

export const login = (email, password) => (dispatch) => {
	dispatch({
		type: Authorization.Login.REQUEST
	});

	const data = {
		email,
		password
	};

	axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, data)
		.then((payload) => {
			if (payload.success) {
				dispatch({
					type: Authorization.Login.SUCCESS
				});

				window.localStorage.setItem('token', payload.message);
				axios.defaults.headers.Authorization = `Bearer ${payload.message}`;
			} else {
				dispatch({
					type: Authorization.Login.FAILURE
				});

				dispatch(addNotification('error', 'Błąd podczas logowania', 'Upewnij się, że wprowadzone dane są poprawne i ponów próbę logowania.'));
			}
		})
		.catch(() => {
			dispatch({
				type: Authorization.Login.FAILURE
			});

			dispatch(addNotification('error', 'Błąd podczas logowania', 'Upewnij się, że wprowadzone dane są poprawne i ponów próbę logowania.'));
		});
};

export const logout = () => (dispatch) => {
	dispatch({
		type: Authorization.Logout.REQUEST
	});

	if (window.localStorage.getItem('token') === null) {
		dispatch({
			type: Authorization.Logout.FAILURE
		});
	} else {
		try {
			window.localStorage.removeItem('token');
			axios.defaults.headers.Authorization = null;

			dispatch({
				type: Authorization.Logout.SUCCESS
			});
		} catch (e) {
			dispatch({
				type: Authorization.Logout.FAILURE
			});

			dispatch(addNotification('error', 'Błąd podczas wylogowywania', 'Proszę spróbuj ponownie wykonać tą operację.'));
		}
	}
};

export const check = () => (dispatch) => {
	dispatch({
		type: Authorization.Check.REQUEST
	});

	axios.get(`${process.env.REACT_APP_API_URL}/auth/check`)
		.then((payload) => {
			if (payload.success) {
				dispatch({
					type: Authorization.Check.SUCCESS
				});

				window.localStorage.setItem('token', payload.message);
				axios.defaults.headers.Authorization = `Bearer ${payload.message}`;
			} else {
				dispatch({
					type: Authorization.Check.FAILURE
				});

				window.localStorage.removeItem('token');
				axios.defaults.headers.Authorization = null;
			}
		})
		.catch(() => {
			dispatch({
				type: Authorization.Check.FAILURE
			});

			window.localStorage.removeItem('token');
			axios.defaults.headers.Authorization = null;
		});
};

export const register = (data) => (dispatch) => {
	dispatch({
		type: Authorization.Register.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, data)
		.then((payload) => {
			if (payload.success) {
				dispatch({
					type: Authorization.Register.SUCCESS
				});
			} else {
				dispatch({
					type: Authorization.Register.FAILURE
				});

				dispatch(addNotification('error', 'Błąd podczas rejestracji', 'Proszę spróbuj ponownie wykonać rejestrację. Jeżeli problem się powtarza to użyj innego adresu e-mail.'));
			}
		})
		.catch(() => {
			dispatch({
				type: Authorization.Register.FAILURE
			});

			dispatch(addNotification('error', 'Błąd podczas rejestracji', 'Proszę spróbuj ponownie wykonać rejestrację. Jeżeli problem się powtarza to użyj innego adresu e-mail.'));
		});
};

export const requestPasswordReset = (email) => (dispatch) => {
	dispatch({
		type: Authorization.RequestPasswordReset.REQUEST
	});

	const data = {
		email
	};

	axios.post(`${process.env.REACT_APP_API_URL}/auth/request_password_reset`, data)
		.then((payload) => {
			if (payload.success) {
				dispatch({
					type: Authorization.RequestPasswordReset.SUCCESS
				});
			} else {
				dispatch({
					type: Authorization.RequestPasswordReset.FAILURE
				});

				dispatch(addNotification('error', 'Błąd podczas operacji', 'Nie możemy przywrócić Twojego hasła. Upewnij się, że zostały wprowadzone poprawne dane i ponów operację.'));
			}
		})
		.catch(() => {
			dispatch({
				type: Authorization.RequestPasswordReset.FAILURE
			});

			dispatch(addNotification('error', 'Błąd podczas operacji', 'Nie możemy przywrócić Twojego hasła. Upewnij się, że zostały wprowadzone poprawne dane i ponów operację.'));
		});
};

export const resetPassword = (email, code, password) => (dispatch) => {
	dispatch({
		type: Authorization.ResetPassword.REQUEST
	});

	const data = {
		password
	};

	axios.post(`${process.env.REACT_APP_API_URL}/auth/reset_password/${code}/${email}`, data)
		.then((payload) => {
			if (payload.success) {
				dispatch({
					type: Authorization.ResetPassword.SUCCESS
				});
			} else {
				dispatch({
					type: Authorization.ResetPassword.FAILURE
				});

				dispatch(addNotification('error', 'Błąd podczas operacji', 'Nie możemy przywrócić Twojego hasła. Upewnij się, że zostały wprowadzone poprawne dane i ponów operację.'));
			}
		})
		.catch(() => {
			dispatch({
				type: Authorization.ResetPassword.FAILURE
			});

			dispatch(addNotification('error', 'Błąd podczas operacji', 'Nie możemy przywrócić Twojego hasła. Upewnij się, że zostały wprowadzone poprawne dane i ponów operację.'));
		});
};

export const resendVerification = (email) => (dispatch) => {
	dispatch({
		type: Authorization.ResendVerification.REQUEST
	});

	const data = {
		email
	};

	axios.post(`${process.env.REACT_APP_API_URL}/auth/resend_verification`, data)
		.then((payload) => {
			if (payload.success) {
				dispatch({
					type: Authorization.ResendVerification.SUCCESS
				});
			} else {
				dispatch({
					type: Authorization.ResendVerification.FAILURE
				});

				dispatch(addNotification('error', 'Błąd podczas operacji', 'Nie możemy wysłać ponownie instrukcji aktywacji konta. Skontaktuj się z naszą pomocą techniczną.'));
			}
		})
		.catch(() => {
			dispatch({
				type: Authorization.ResendVerification.FAILURE
			});

			dispatch(addNotification('error', 'Błąd podczas operacji', 'Nie możemy wysłać ponownie instrukcji aktywacji konta. Skontaktuj się z naszą pomocą techniczną.'));
		});
};

export const verify = (code) => (dispatch) => {
	dispatch({
		type: Authorization.Verify.REQUEST
	});

	axios.post(`${process.env.REACT_APP_API_URL}/auth/verify/${code}`)
		.then((payload) => {
			if (payload.success) {
				dispatch({
					type: Authorization.Verify.SUCCESS
				});
			} else {
				dispatch({
					type: Authorization.Verify.FAILURE
				});

				dispatch(addNotification('error', 'Błąd podczas operacji', 'Nie możemy zweryfikować Twojego konta. Link aktywacyjny wygasł lub jest niepoprawny.'));
			}
		})
		.catch(() => {
			dispatch({
				type: Authorization.Verify.FAILURE
			});

			dispatch(addNotification('error', 'Błąd podczas operacji', 'Nie możemy zweryfikować Twojego konta. Link aktywacyjny wygasł lub jest niepoprawny.'));
		});
};
