import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Notification extends Component {
	componentDidMount() {
		this.timeout = setTimeout(() => {
			this.props.removeNotification(this.props.id);
		}, 5000);
	}

	handleClose = () => {
		const {removeNotification, id} = this.props;

		clearTimeout(this.timeout);
		removeNotification(id);
	}

	render() {
		const {
			title,
			message,
			type
		} = this.props;

		return (
			<div className={classNames('notification', type)}>
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button
					type="button"
					className="icon-close"
					onClick={() => this.handleClose()}
				/>
				<div><p><small>{title}</small></p></div>
				<div><p>{message}</p></div>
			</div>
		);
	}
}

Notification.propTypes = {
	id: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	message: PropTypes.string,
	type: PropTypes.oneOf(['success', 'error', 'info']).isRequired,
	removeNotification: PropTypes.func.isRequired
};

Notification.defaultProps = {
	message: null
};

export default Notification;
