import React from 'react';

import Layout from '../../components/Layout';

const Fundation = () => (
	<Layout active="fundation">
		<i />
	</Layout>
);

export default Fundation;
