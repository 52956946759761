import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Modal = (props) => {
	const {
		title,
		className,
		children,
		handleBack,
		handleClose
	} = props;

	return ReactDOM.createPortal((
		<div className={classNames('modal', className)}>
			<div className="modal-wrapper">
				{
					handleBack && (
						<button // eslint-disable-line jsx-a11y/control-has-associated-label
							type="button"
							className="icon-back"
							onClick={() => handleBack()}
						/>
					)
				}
				{
					handleClose && (
						<button // eslint-disable-line jsx-a11y/control-has-associated-label
							type="button"
							className="icon-close"
							onClick={() => handleClose()}
						/>
					)
				}
				<div className="modal-content">
					<p className="title">{title}</p>
					<div className="content">
						{children}
					</div>
				</div>
			</div>
		</div>
	), document.getElementById('modals'));
};

Modal.propTypes = {
	title: PropTypes.string.isRequired,
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	handleBack: PropTypes.func,
	handleClose: PropTypes.func
};

Modal.defaultProps = {
	className: '',
	handleBack: null,
	handleClose: null
};

export default Modal;
