import React from 'react';
import PropTypes from 'prop-types';

import {MenuItem} from './components';
import Login from '../Login';

const menu = [
	{
		name: 'about',
		label: 'jak to działa',
		href: '/about'
	},
	{
		name: 'home',
		label: 'rozmawiaj',
		href: '/'
	},
	{
		name: 'fundation',
		label: 'fundacja',
		href: '/fundation'
	},
	{
		name: 'knowledge',
		label: 'strefa wiedzy',
		href: '/knowledge'
	},
	{
		name: 'contact',
		label: 'kontakt',
		href: '/contact'
	}
];

const Topbar = (props) => {
	const {active} = props;

	return (
		<>
			<header>
				<div className="topbar">
					<div>
						<p>{process.env.REACT_APP_PROJECT_NAME}</p>
					</div>
					<Login />
				</div>
				<nav>
					{
						menu.map((menuItem) => (
							<MenuItem
								key={menuItem.label}
								name={menuItem.label}
								to={menuItem.href}
								active={active === menuItem.name}
							/>
						))
					}
				</nav>
			</header>
		</>
	);
};

Topbar.propTypes = {
	active: PropTypes.string
};

Topbar.defaultProps = {
	active: null
};

export default Topbar;
