import shortid from 'shortid';

import {Notification} from '../util/actionTypes';

export const addNotification = (type, title, message = null) => (dispatch) => {
	dispatch({
		type: Notification.Add,
		notification: {
			id: shortid.generate(),
			type,
			title,
			message
		}
	});
};

export const removeNotification = (id) => (dispatch) => {
	dispatch({
		type: Notification.Remove,
		id
	});
};
