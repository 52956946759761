import React, {Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class Bookshelf extends Component {
	state = {
		open: null
	}

	componentDidMount() {
		if (this.props.active && this.props.active.sub) {
			this.setState({
				open: this.props.active.item
			});
		}
	}

	handleOpen = (item) => {
		const {open} = this.state;

		this.setState({
			open: open === item ? null : item
		});
	}

	handleChange = (item, sub = null) => {
		const {handleChange} = this.props;

		this.setState({
			open: sub ? item : null
		});

		handleChange({
			item,
			sub
		});
	}

	render() {
		let {children} = this.props;
		const {active, wide} = this.props;
		const {open} = this.state;

		children = Array.isArray(children) ? children : [children];

		const activeItem = active && children.find((c) => c.props.title === active.item);

		return (
			<div className="bookshelf">
				<div className="row">
					<div className={wide ? 'col-3-12' : 'col-4-12'}>
						<ul className="menu">
							{
								children.map((c) => (
									c.props.withBookmarks ? (
										<li key={c.props.title} className={classNames('with-submenu', {open: open === c.props.title, active: (active && active.item === c.props.title)})}>
											<a href="#" onClick={() => this.handleOpen(c.props.title)}>{c.props.title}</a> {/* eslint-disable-line */}
											<ul>
												{
													c.props.children.map((bm) => (
														<li className={classNames({active: (active && active.item === c.props.title && active.sub === bm.props.title)})} key={`${c.props.title}_${bm.props.title}`}>
															<a href="#" onClick={() => this.handleChange(c.props.title, bm.props.title)}>{bm.props.title}</a> {/* eslint-disable-line */}
														</li>
													))
												}
											</ul>
										</li>
									) : (
										<li className={classNames({active: (active && active.item === c.props.title)})} key={c.props.title}>
											<a href="#" onClick={() => this.handleChange(c.props.title)}>{c.props.title}</a> {/* eslint-disable-line */}
										</li>
									)
								))
							}
						</ul>
					</div>
					<div className={wide ? 'col-9-12' : 'col-8-12'}>
						<div className="content">
							{
								activeItem && (
									activeItem.props.withBookmarks ? (
										activeItem.props.children.find((bm) => bm.props.title === active.sub).props.children
									) : (
										activeItem.props.children
									)
								)
							}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Bookshelf.propTypes = {
	active: PropTypes.object,
	handleChange: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
	wide: PropTypes.bool
};

Bookshelf.defaultProps = {
	active: null,
	wide: false
};

export default Bookshelf;
