import {connect} from 'react-redux';

import ResetPassword from './ResetPassword';
import {resetPassword} from '../../actions/authorization';
import {addNotification} from '../../actions/notification';

const mapStateToProps = (state, ownProps) => ({
	email: ownProps.match.params.email,
	code: ownProps.match.params.code,
	passwordReset: state.authorization.passwordReset
});

const mapDispatchToProps = (dispatch) => ({
	resetPassword: (email, resetCode, password) => dispatch(resetPassword(email, resetCode, password)),
	addNotification: (type, title, message) => dispatch(addNotification(type, title, message))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ResetPassword);
