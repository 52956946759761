import React from 'react';
import PropTypes from 'prop-types';

const Tab = (props) => {
	const {children} = props;

	return (
		<div>
			{children}
		</div>
	);
};

Tab.propTypes = {
	label: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
	text: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
	children: PropTypes.node.isRequired
};

export default Tab;
