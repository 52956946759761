import React, {Component} from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import classNames from 'classnames';

class SingleCheckbox extends Component {
	state = {
		error: null
	};

	constructor(props) {
		super(props);

		this.input = React.createRef();
	}

	componentDidMount() {
		const {handleError, name} = this.props;

		if (handleError) {
			const error = !this.input.current.checkValidity();
			handleError(name, error);
		}
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.refreshError && this.props.refreshError && this.props.handleError) {
			const error = !this.input.current.checkValidity();

			this.setState({
				error
			});

			this.props.handleError(this.props.name, error);
		}

		if (prevProps.value !== this.props.value && this.props.handleError) {
			if (this.props.value) {
				const error = !this.input.current.checkValidity();

				this.setState({
					error
				});

				this.props.handleError(this.props.name, error);
			} else {
				this.setState({
					error: null
				});
			}
		}
	}

	handleChange = (e) => {
		const {handleChange, handleError, name} = this.props;
		const {checked} = e.target;

		handleChange(name, checked);

		const error = !e.target.checkValidity();

		if (handleError) {
			this.setState({
				error
			});

			handleError(name, error);
		}
	}

	render() {
		const {
			id: propId,
			name,
			value,
			required,
			pattern,
			inlineLabel,
			label,
			checkboxLabel
		} = this.props;

		const id = propId || shortid.generate();

		const {error} = this.state;

		const divClass = classNames('form-control', {'inline-label': inlineLabel, error: error === true, validated: error === false});

		return (
			<div className={divClass}>
				<div className="single-checkbox">
					{
						label && (
							<label htmlFor={id}>{label}</label>
						)
					}
					<label htmlFor={id}>
						<input
							ref={this.input}
							id={id}
							name={name}
							type="checkbox"
							onChange={this.handleChange}
							pattern={pattern}
							required={required}
							checked={value}
						/>
						{checkboxLabel}
					</label>
				</div>
			</div>
		);
	}
}

SingleCheckbox.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string.isRequired,
	value: PropTypes.bool.isRequired,
	required: PropTypes.bool,
	label: PropTypes.string,
	checkboxLabel: PropTypes.string.isRequired,
	inlineLabel: PropTypes.bool,
	pattern: PropTypes.string,
	handleChange: PropTypes.func.isRequired,
	handleError: PropTypes.func,
	refreshError: PropTypes.bool
};

SingleCheckbox.defaultProps = {
	id: null,
	label: null,
	inlineLabel: false,
	required: false,
	pattern: null,
	handleError: null,
	refreshError: false
};

export default SingleCheckbox;
