import {connect} from 'react-redux';

import Notification from './Notification';
import {removeNotification} from '../../../actions/notification';

const mapDispatchToProps = (dispatch) => ({
	removeNotification: (id) => dispatch(removeNotification(id))
});

export default connect(
	null,
	mapDispatchToProps
)(Notification);
