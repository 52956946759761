import React from 'react';
import PropTypes from 'prop-types';
import {Notification} from '..';

const Notifications = (props) => {
	const {
		notifications
	} = props;

	return (
		<div className="notifications-box">
			{
				notifications.map((notification) => (
					<Notification
						key={notification.id}
						id={notification.id}
						title={notification.title}
						message={notification.message}
						type={notification.type}
					/>
				))
			}
		</div>
	);
};

Notifications.propTypes = {
	notifications: PropTypes.array.isRequired
};

export default Notifications;
