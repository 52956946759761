import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Tabs extends Component {
	handleChange = (tab) => {
		const {active, handleChange} = this.props;

		handleChange(active === tab ? null : tab);
	}

	render() {
		let {children} = this.props;
		const {active} = this.props;

		children = Array.isArray(children) ? children : [children];

		return (
			<div className={classNames('tabs', {open: active !== null})}>
				<div className={classNames('header', {'fit-screen': active === null})}>
					{
						children.map((c) => (
							<button
								key={c.props.label}
								type="button"
								className={classNames({active: active === c.props.label})}
								onClick={() => this.handleChange(c.props.label)}
							>
								<i />
								<h1>
									{c.props.label}
								</h1>
								<h6>
									{c.props.text}
								</h6>
							</button>
						))
					}
				</div>
				{
					active && (
						<div className="content">
							{
								children.find((c) => c.props.label === active)
							}
						</div>
					)
				}
			</div>
		);
	}
}

Tabs.propTypes = {
	children: PropTypes.node.isRequired,
	active: PropTypes.string,
	handleChange: PropTypes.func.isRequired
};

Tabs.defaultProps = {
	active: null
};

export default Tabs;
