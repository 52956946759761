import {connect} from 'react-redux';

import MyAccount from './MyAccount';
import {getAccount} from '../../actions/myAccount';
import {addNotification} from '../../actions/notification';

const mapStateToProps = (state) => ({
	account: state.profile.account
});

const mapDispatchToProps = (dispatch) => ({
	getAccount: () => dispatch(getAccount()),
	addNotification: (type, title, message) => dispatch(addNotification(type, title, message))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MyAccount);
