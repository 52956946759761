import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Layout from '../../components/Layout';
import {Bookmark, Book, Bookshelf} from '../../components/Other';
import {AccountTypeName} from '../../util/types';
import {Form, Input} from '../../components/Form';
import {handleChange} from '../../util/common';

class MyAccount extends Component {
	state = {
		account: null,
		active: {
			item: 'Profil',
			sub: 'Moje dane'
		},
		nick: '',
		fullName: '',
		email: '',
		password: '',
		phoneNumber: '',
		address: '',
		sex: '',
		birthday: '',
		firstName: '',
		lastName: '',
		cardNumber: '',
		expirationDate: '',
		cvv: ''
	};

	componentDidMount() {
		const {
			account,
			getAccount
		} = this.props;

		if (account) {
			this.setAccount();
		} else {
			getAccount();
		}
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.account && this.props.account) {
			this.setAccount();
		}
	}

	setAccount = () => {
		const {account} = this.props;

		this.setState({
			account: _.cloneDeep(account)
		});
	}

	handleBookChange = (book) => {
		this.setState({
			active: book
		});
	}

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {
			active,
			account,
			nick,
			fullName,
			email,
			password,
			phoneNumber,
			address,
			sex,
			birthday,
			firstName,
			lastName,
			cardNumber,
			expirationDate,
			cvv
		} = this.state;

		if (!account) {
			return (
				<Layout size="thin" />
			);
		}

		if (account.account_type.name === AccountTypeName.THERAPIST) {
			return (
				<Layout size="ultra-thin">
					<Bookshelf active={active} handleChange={this.handleBookChange}>
						<Book
							title="Profil"
							withBookmarks
						>
							<Bookmark title="Moje dane">
								<Form>
									<button
										className="save-btn"
										type="button"
									>
										zapisz
									</button>
									<Input
										inlineLabel
										label="Imię i nazwisko"
										name="fullName"
										value={fullName}
										type="text"
										handleChange={this.handleChange}
									/>
									<Input
										inlineLabel
										label="E-mail"
										name="email"
										value={email}
										type="email"
										handleChange={this.handleChange}
									/>
									<Input
										inlineLabel
										label="Hasło"
										name="password"
										value={password}
										type="password"
										handleChange={this.handleChange}
									/>
									<Input
										inlineLabel
										label="Telefon"
										name="phoneNumber"
										value={phoneNumber}
										type="tel"
										handleChange={this.handleChange}
									/>
									<Input
										inlineLabel
										label="Miejsce zamieszkania"
										name="address"
										value={address}
										type="text"
										handleChange={this.handleChange}
									/>
									<Input
										inlineLabel
										label="Płeć"
										name="sex"
										value={sex}
										type="text"
										handleChange={this.handleChange}
									/>
									<Input
										inlineLabel
										label="Data urodzenia"
										name="birthday"
										value={birthday}
										type="date"
										handleChange={this.handleChange}
									/>
								</Form>
							</Bookmark>
							<Bookmark title="O mnie">
								<p className="border-btm-l1">
									{fullName}
								</p>
								<p>
									Dodaj swój opis
								</p>
							</Bookmark>
							<Bookmark title="Dodaj nagranie">
								<p className="border-btm-l1">
									Twoje nagranie
								</p>
								<p>
									Uważamy, że dla naszych klientów barwa głosu jest istotnym elementem w wyborze odpowiedniego specjalisty.
								</p>
								<p>
									Dodaj próbkę głosu na którym czytasz swój opis
								</p>
							</Bookmark>
							<Bookmark title="Zakres pracy">
								<p className="border-btm-l1">
									Główne specjalizacje
								</p>
							</Bookmark>
							<Bookmark title="Doświadczenie">
								<Form>
									<button
										className="save-btn"
										type="button"
									>
										zapisz
									</button>
									<p className="border-btm-l1">
										Twoje doświadczenie
									</p>
									<p>
										Uzupełnij informacje
									</p>
									<p className="border-btm-l1">
										Edukacja
									</p>
									<p>
										Uzupełnij informacje
									</p>
									<p className="border-btm-l1">
										Język
									</p>
									<p>
										Uzupełnij informacje
									</p>
								</Form>
							</Bookmark>
							<Bookmark title="Podgląd profilu">
								<p>tresc</p>
							</Bookmark>
						</Book>
						<Book
							title="Rozliczenia"
							withBookmarks
						>
							<Bookmark title="Informacje">
								<p>tresc</p>
							</Bookmark>
							<Bookmark title="Dokumenty">
								<p>tresc</p>
							</Bookmark>
						</Book>
						<Book
							title="Ustawienia"
						>
							<p>tresc</p>
						</Book>
					</Bookshelf>
				</Layout>
			);
		}

		return (
			<Layout size="ultra-thin">
				<Bookshelf active={active} handleChange={this.handleBookChange}>
					<Book
						title="Profil"
					>
						<Form>
							<button
								className="save-btn"
								type="button"
							>
								zapisz
							</button>
							<Input
								inlineLabel
								label="Nick"
								name="nick"
								value={nick}
								type="text"
								handleChange={this.handleChange}
							/>
							<Input
								inlineLabel
								label="Imię i nazwisko"
								name="fullname"
								value={fullName}
								type="text"
								handleChange={this.handleChange}
							/>
							<Input
								inlineLabel
								label="E-mail"
								name="email"
								value={email}
								type="email"
								handleChange={this.handleChange}
							/>
							<Input
								inlineLabel
								label="Hasło"
								name="password"
								value={password}
								type="password"
								handleChange={this.handleChange}
							/>
							<Input
								inlineLabel
								label="Telefon"
								name="phoneNumber"
								value={phoneNumber}
								type="tel"
								handleChange={this.handleChange}
							/>
							<Input
								inlineLabel
								label="Miejsce zamieszkania"
								name="address"
								value={address}
								type="text"
								handleChange={this.handleChange}
							/>
							<Input
								inlineLabel
								label="Płeć"
								name="sex"
								value={sex}
								type="text"
								handleChange={this.handleChange}
							/>
							<Input
								inlineLabel
								label="Data urodzenia"
								name="birthday"
								value={birthday}
								type="date"
								handleChange={this.handleChange}
							/>
						</Form>
					</Book>
					<Book
						title="Płatności"
						withBookmarks
					>
						<Bookmark title="moja karta">
							<Form>
								<button
									className="save-btn"
									type="button"
								>
									zapisz
								</button>
								<Input
									inlineLabel
									label="Imię"
									name="firstName"
									value={firstName}
									type="text"
									handleChange={this.handleChange}
								/>
								<Input
									inlineLabel
									label="Nazwisko"
									name="lastName"
									value={lastName}
									type="text"
									handleChange={this.handleChange}
								/>
								<Input
									inlineLabel
									label="Numer karty"
									name="cardNumber"
									value={cardNumber}
									type="number"
									handleChange={this.handleChange}
								/>
								<Input
									inlineLabel
									label="Data wygaśnięcia"
									name="expirationDate"
									value={expirationDate}
									type="text"
									handleChange={this.handleChange}
								/>
								<Input
									inlineLabel
									label="Kod CVV"
									name="cvv"
									value={cvv}
									type="number"
									handleChange={this.handleChange}
								/>
							</Form>
						</Bookmark>
						<Bookmark title="historia płatności">
							<table>
								<tr>
									<th>data</th>
									<th>metoda płatności</th>
									<th>kwota PLN</th>
								</tr>
								<tr>
									<td>1.01.2000</td>
									<td>**** **** **** **** 4525</td>
									<td>100</td>
								</tr>
								<tr>
									<td>17.01.2020</td>
									<td>**** **** **** **** 4525</td>
									<td>10</td>
								</tr>
							</table>
						</Bookmark>
					</Book>
					<Book
						title="Połączenia"
						withBookmarks
					>
						<Bookmark title="zaplanowane">
							<table>
								<tr>
									<th>data</th>
									<th>specjalista</th>
									<th> </th>
								</tr>
								<tr>
									<td>1.01.2000</td>
									<td>Jan Kowalski</td>
									<td>
										<button type="button">
											odwołaj
										</button>
									</td>
								</tr>
								<tr>
									<td>17.01.2020</td>
									<td>Andrzej Nowak</td>
									<td>
										<button type="button">
											odwołaj
										</button>
									</td>
								</tr>
							</table>
						</Bookmark>
						<Bookmark title="historia">
							<table>
								<tr>
									<th>data</th>
									<th>specjalista</th>
									<th> </th>
								</tr>
								<tr>
									<td>1.01.2000</td>
									<td>Jan Kowalski</td>
									<td>
										<button type="button">
											szczegóły
										</button>
									</td>
								</tr>
								<tr>
									<td>17.01.2020</td>
									<td>Andrzej Nowak</td>
									<td>
										<button type="button">
											szczegóły
										</button>
									</td>
								</tr>
							</table>
						</Bookmark>
					</Book>
					<Book
						title="Moja strefa"
						withBookmarks
					>
						<Bookmark
							title="moja ankieta"
							className="letter-space-1"
						>
							<p className="border-btm-l1">
								Wypełnij ankietę
							</p>
							<p>
								Ankieta umożliwiająca idealne dopasowanie psychologa do Twoich potrzeb.
							</p>
							<p>
								Pamietaj, nie przechowujemy informacji z ankiety, ankieta samoczynnie generuje unikalny dla Ciebie “kod grupy psychologow”
							</p>
						</Bookmark>
						<Bookmark title="preferencje">
							<p>tresc</p>
						</Bookmark>
						<Bookmark title="notatki">
							<p>tresc</p>
						</Bookmark>
					</Book>
				</Bookshelf>
			</Layout>
		);
	}
}

MyAccount.propTypes = {
	account: PropTypes.object,
	getAccount: PropTypes.func.isRequired
};

MyAccount.defaultProps = {
	account: null
};

export default MyAccount;
