import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import {handleChange, handleError} from '../../util/common';
import {Modal} from '../../components/Other';
import {Form, Input} from '../../components/Form';
import {StepType} from '../../util/types';
import Layout from '../../components/Layout';

class ResetPassword extends Component {
	state = {
		step: StepType.RESET_PASSWORD,
		password1: '',
		password2: ''
	};

	componentDidMount() {}

	componentDidUpdate(prevProps) {
		if (!prevProps.passwordReset && this.props.passwordReset) {
			this.setState({
				password1: '',
				password2: '',
				step: StepType.RESET_PASSWORD_CONFIRM
			});

			this.props.history.push('/');
		}
	}

	handleError = (name, error) => handleError(this, name, error);

	handleChange = (name, value) => handleChange(this, name, value);

	resetPassword = () => {
		const {
			email,
			code,
			resetPassword,
			addNotification
		} = this.props;

		const {
			password1,
			password2
		} = this.state;

		if (password1 !== password2) {
			addNotification('error', 'Błędy w formularzu', 'Upewnij się, że to samo hasło zostało podane w obu polach tekstowych.');
			return false;
		}

		resetPassword(email, code, password1);

		return true;
	}

	render() {
		const {
			step,
			password1,
			password2
		} = this.state;

		return (
			<Layout>
				{
					step === StepType.RESET_PASSWORD && (
						<Modal className="login-modal" title="Resetowanie hasła">
							<Form>
								<Input
									handleChange={this.handleChange}
									handleError={this.handleError}
									name="password1"
									value={password1}
									type="password"
									placeholder="hasło"
									pattern=".{8,}"
									required
								/>
								<Input
									handleChange={this.handleChange}
									handleError={this.handleError}
									name="password2"
									value={password2}
									type="password"
									placeholder="powtórz hasło"
									pattern=".{8,}"
									required
								/>
								<button
									type="button"
									className="btn normal"
									onClick={this.resetPassword}
								>
									<p>zresetuj hasło</p>
								</button>
								<div className="footer">
									<p>Ustaw nowe hasło</p>
								</div>
							</Form>
						</Modal>
					)
				}
				{
					step === StepType.RESET_PASSWORD_CONFIRM && (
						<Modal className="login-modal" title="Resetowanie hasła">
							Hasło zostało pomyślnie zmienione
						</Modal>
					)
				}
			</Layout>
		);
	}
}

ResetPassword.propTypes = {
	email: PropTypes.string.isRequired,
	code: PropTypes.string.isRequired,
	resetPassword: PropTypes.func.isRequired,
	passwordReset: PropTypes.bool.isRequired,
	addNotification: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(ResetPassword);
