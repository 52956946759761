import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {Form, Input, SingleCheckbox} from '../Form';
import {handleChange, handleError} from '../../util/common';
import {AccountTypeName, StepType} from '../../util/types';
import {Loader, Modal} from '../Other';

class Login extends Component {
	state = {
		email: '',
		password: '',
		agreement: false,
		open: false,
		step: null,
		refreshError: false
	};

	componentDidMount() {
		const {check} = this.props;

		check();
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.loggedIn && this.props.loggedIn) {
			this.props.addNotification('success', 'Zostałeś zalogowany', `Witaj ponownie w ${process.env.REACT_APP_PROJECT_NAME}. Teraz możesz korzystać z pełnej funkcjonalności naszej aplikacji.`);

			this.setState({
				open: false,
				email: '',
				password: ''
			});
		}

		if (!prevProps.loggedOut && this.props.loggedOut) {
			this.props.addNotification('success', 'Zostałeś wylogowany', 'Wierzymy, że jeszcze do nas wrócisz!');
		}

		if (!prevProps.registered && this.props.registered) {
			this.props.addNotification('success', 'Zarejestrowałeś się', `Witaj wśród użytkowników ${process.env.REACT_APP_PROJECT_NAME}. Sprawdź swoją pocztę pocztową, aby uzyskać dalsze instrukcje.`);

			this.setState({
				password: '',
				step: StepType.SIGNUP_CONFIRM
			});
		}

		if (!prevProps.passwordResetRequested && this.props.passwordResetRequested) {
			this.props.addNotification('success', 'Wiadomość została wysłana', 'Na Twoją skrzynkę pocztową wysłaliśmy instrukcje dotyczące przywracania hasła.');

			this.setState({
				step: StepType.REQUEST_RESET_PASSWORD_CONFIRM
			});
		}

		if (!prevProps.verificationResent && this.props.verificationResent) {
			this.props.addNotification('success', 'Wiadomość została wysłana', 'Na Twoją skrzynkę pocztową ponownie wysłaliśmy instrukcje dotyczące aktywacji Twojego konta.');
		}
	}

	signUpOn = () => {
		this.setState({
			open: true,
			step: StepType.SIGNUP_CHOOSE
		});
	}

	signInOn = () => {
		this.setState({
			open: true,
			step: StepType.SIGNIN
		});
	}

	handleError = (name, error) => handleError(this, name, error);

	handleChange = (name, value) => handleChange(this, name, value);

	handleClose = () => {
		this.setState({
			open: false,
			step: null
		});
	}

	logIn = () => {
		const {
			email,
			password,
			errors
		} = this.state;

		const {
			login,
			addNotification
		} = this.props;

		this.setState({
			refreshError: true
		}, () => {
			this.setState({
				refreshError: false
			});
		});

		if (errors.email || errors.password) {
			addNotification('error', 'Błędy w formularzu', 'Sprawdź wprowadzone dane i popraw wszystkie błędy, które wskazaliśmy.');
			return false;
		}

		login(email, password);

		return true;
	}

	register = () => {
		const {
			email,
			password,
			agreement,
			step,
			errors
		} = this.state;

		const {
			register,
			addNotification
		} = this.props;

		this.setState({
			refreshError: true
		}, () => {
			this.setState({
				refreshError: false
			});
		});

		if (errors.email || errors.password || errors.agreement) {
			addNotification('error', 'Błędy w formularzu', 'Sprawdź wprowadzone dane i popraw wszystkie błędy, które wskazaliśmy.');
			return false;
		}

		if (!agreement) {
			addNotification('error', 'Błędy w formularzu', 'Musisz zaakceptować wszystkie zgody przed kontynuacją operacji.');
			return false;
		}

		register({
			email,
			password,
			account_type: step === StepType.SIGNUP_AS_THERAPIST ? AccountTypeName.THERAPIST : AccountTypeName.CLIENT
		});

		return true;
	}

	requestPasswordReset = () => {
		const {
			email
		} = this.state;

		const {
			requestPasswordReset
		} = this.props;

		requestPasswordReset(email);

		return true;
	}

	resendVerification = () => {
		const {
			email
		} = this.state;

		const {
			resendVerification
		} = this.props;

		resendVerification(email);

		return true;
	}

	render() {
		const {
			email,
			password,
			agreement,
			open,
			step,
			refreshError
		} = this.state;

		const {
			authorized,
			logout,
			loading
		} = this.props;

		return (
			<>
				{
					authorized ? (
						<div className="login">
							<Link
								to="/my_account"
							>
								moje konto
							</Link>
							<button
								type="button"
								onClick={logout}
							>
								wyloguj
							</button>
						</div>
					) : (
						<div className="login">
							<button
								type="button"
								onClick={this.signUpOn}
							>
								założ konto
							</button>
							<button
								type="button"
								onClick={this.signInOn}
							>
								zaloguj
							</button>
						</div>
					)
				}
				{
					open && (
						<>
							{
								step === StepType.SIGNIN && (
									<Modal
										className="login-modal with-footer"
										title="Logowanie"
										handleClose={this.handleClose}
									>
										<Form>
											<Input
												handleChange={this.handleChange}
												handleError={this.handleError}
												name="email"
												value={email}
												type="email"
												placeholder="adres e-mail/nick"
												refreshError={refreshError}
												required
											/>
											<Input
												handleChange={this.handleChange}
												handleError={this.handleError}
												name="password"
												value={password}
												type="password"
												placeholder="hasło"
												refreshError={refreshError}
												pattern=".{8,}"
												required
											/>
											<button
												type="button"
												onClick={() => this.setState({step: StepType.REQUEST_RESET_PASSWORD})}
												className="reset"
											>
												<p>zapomniałeś hasła?</p>
											</button>
											<button
												type="button"
												className="btn normal "
												onClick={this.logIn}
											>
												Zaloguj się
											</button>
											<button
												type="button"
												className="fb-btn"
											>
												<img
													alt="client"
													src={`${process.env.PUBLIC_URL}/static/images/fb_btn.png`}
												/>
											</button>
										</Form>
										<div className="footer">
											<button
												type="button"
												onClick={() => this.setState({step: StepType.SIGNUP_CHOOSE})}
											>
												<p>Nie masz konta?</p>
												<p>Zarejestruj się</p>
											</button>
										</div>
									</Modal>
								)
							}
							{
								step === StepType.REQUEST_RESET_PASSWORD && (
									<Modal
										className="login-modal"
										title="Resetowanie hasła"
										handleClose={this.handleClose}
										handleBack={() => this.setState({step: StepType.SIGNIN})}
									>
										<Form>
											<Input
												handleChange={this.handleChange}
												handleError={this.handleError}
												name="email"
												value={email}
												type="email"
												placeholder="email"
												refreshError={refreshError}
												pattern=".{8,}"
												required
											/>
										</Form>
										<button
											type="button"
											className="btn normal"
											onClick={this.requestPasswordReset}
										>
											Resetuj hasło
										</button>
										<div className="footer">
											<p>W wiadomości e-mail otrzymasz dalsze instrukcje</p>
										</div>
									</Modal>
								)
							}
							{
								step === StepType.REQUEST_RESET_PASSWORD_CONFIRM && (
									<Modal
										className="login-modal"
										title="Resetowanie hasła"
										handleClose={this.handleClose}
										handleBack={() => this.setState({step: StepType.REQUEST_RESET_PASSWORD})}
									>
										<div className="image-picker">
											<div className="text">
												Na podany przez Ciebie adres email wysłaliśmy link umożliwiający zresetowanie hasła.
											</div>
											<img
												alt="client"
												src={`${process.env.PUBLIC_URL}/static/images/at_signup.png`}
											/>
											<img
												alt="spec"
												src={`${process.env.PUBLIC_URL}/static/images/specialist_signup.png`}
											/>
										</div>
										<div className="footer">
											<button
												type="button"
												onClick={this.requestPasswordReset}
											>
												<p>E-mail nie dotarł?</p>
												<p>Wyślij jeszcze raz</p>
											</button>
										</div>
									</Modal>
								)
							}
							{
								step === StepType.SIGNUP_CHOOSE && (
									<Modal
										className="login-modal"
										title="Zarejestruj się jako"
										handleClose={this.handleClose}
										handleBack={() => this.setState({step: StepType.SIGNIN})}
									>
										<div className="image-picker">
											<button
												type="button"
												onClick={() => this.setState({step: StepType.SIGNUP_AS_CLIENT})}
											>
												<img
													alt="client"
													src={`${process.env.PUBLIC_URL}/static/images/user_signup.png`}
												/>
											</button>
											<button
												type="button"
												onClick={() => this.setState({step: StepType.SIGNUP_AS_THERAPIST})}
											>
												<img
													alt="spec"
													src={`${process.env.PUBLIC_URL}/static/images/specialist_signup.png`}
												/>
											</button>
										</div>
										<div className="row">
											<div className="col-6-12">
												<button
													type="button"
													className="btn normal"
													onClick={() => this.setState({step: StepType.SIGNUP_AS_CLIENT})}
												>
													Użytkownik
												</button>
											</div>
											<div className="col-6-12">
												<button
													type="button"
													className="btn normal"
													onClick={() => this.setState({step: StepType.SIGNUP_AS_THERAPIST})}
												>
													Specjalista
												</button>
											</div>
										</div>
									</Modal>
								)
							}
							{
								step === StepType.SIGNUP_AS_THERAPIST && (
									<Modal
										className="login-modal with-footer"
										title="Zarejestruj się jako specjalista"
										handleClose={this.handleClose}
										handleBack={() => this.setState({step: StepType.SIGNUP_CHOOSE})}
									>
										<Form>
											<Input
												handleChange={this.handleChange}
												handleError={this.handleError}
												name="email"
												value={email}
												type="email"
												placeholder="adres e-mail/nick"
												refreshError={refreshError}
												required
											/>
											<Input
												handleChange={this.handleChange}
												handleError={this.handleError}
												name="password"
												value={password}
												type="password"
												placeholder="hasło"
												refreshError={refreshError}
												pattern=".{8,}"
												required
											/>
											<SingleCheckbox
												handleChange={this.handleChange}
												name="agreement"
												value={agreement}
												checkboxLabel="zapoznałem się i akceptuję regulamin"
											/>
											<button
												type="button"
												className="btn normal"
												onClick={this.register}
											>
												Zarejestruj się
											</button>
											<button
												type="button"
												className="fb-btn"
											>
												<img
													alt="client"
													src={`${process.env.PUBLIC_URL}/static/images/fb_btn.png`}
												/>
											</button>
										</Form>
										<div className="footer">
											<button
												type="button"
												onClick={() => this.setState({step: StepType.SIGNIN})}
											>
												<p>Masz konto?</p>
												<p>Zaloguj się</p>
											</button>
										</div>
									</Modal>
								)
							}
							{
								step === StepType.SIGNUP_AS_CLIENT && (
									<Modal
										className="login-modal with-footer"
										title="Zarejestruj się jako użytkownik"
										handleClose={this.handleClose}
										handleBack={() => this.setState({step: StepType.SIGNUP_CHOOSE})}
									>
										<Form>
											<Input
												handleChange={this.handleChange}
												handleError={this.handleError}
												name="email"
												value={email}
												type="email"
												placeholder="adres e-mail/nick"
												refreshError={refreshError}
												required
											/>
											<Input
												handleChange={this.handleChange}
												handleError={this.handleError}
												name="password"
												value={password}
												type="password"
												placeholder="hasło"
												refreshError={refreshError}
												pattern=".{8,}"
												required
											/>
											<SingleCheckbox
												handleChange={this.handleChange}
												name="agreement"
												value={agreement}
												checkboxLabel="zapoznałem się i akceptuję regulamin"
											/>
											<button
												type="button"
												className="btn normal"
												onClick={this.register}
											>
												Zarejestruj się
											</button>
											<button
												type="button"
												className="fb-btn"
											>
												<img
													alt="client"
													src={`${process.env.PUBLIC_URL}/static/images/fb_btn.png`}
												/>
											</button>
										</Form>
										<div className="footer">
											<button
												type="button"
												onClick={() => this.setState({step: StepType.SIGNIN})}
											>
												<p>Masz konto?</p>
												<p>Zaloguj się</p>
											</button>
										</div>
									</Modal>
								)
							}
							{
								step === StepType.SIGNUP_CONFIRM && (
									<Modal
										className="login-modal"
										title="Dokończ rejestrację"
										handleClose={this.handleClose}
										handleBack={() => this.setState({step: StepType.SIGNIN})}
									>
										<div className="image-picker">
											<div className="text">
												Na podany przez Ciebie adres email wysłaliśmy link aktywacyjny.
											</div>
											<img
												alt="client"
												src={`${process.env.PUBLIC_URL}/static/images/at_signup.png`}
											/>
											<img
												alt="spec"
												src={`${process.env.PUBLIC_URL}/static/images/specialist_signup.png`}
											/>
										</div>
										<div className="footer">
											<button
												type="button"
												onClick={this.resendVerification}
											>
												<p>E-mail nie dotarł?</p>
												<p>Wyślij jeszcze raz</p>
											</button>
										</div>
									</Modal>
								)
							}
						</>
					)
				}
				{
					loading && <Loader text="przetwarzanie..." />
				}
			</>
		);
	}
}

Login.propTypes = {
	check: PropTypes.func.isRequired,
	authorized: PropTypes.bool.isRequired,
	login: PropTypes.func.isRequired,
	loggedIn: PropTypes.bool.isRequired,
	logout: PropTypes.func.isRequired,
	loggedOut: PropTypes.bool.isRequired,
	register: PropTypes.func.isRequired,
	registered: PropTypes.bool.isRequired,
	requestPasswordReset: PropTypes.func.isRequired,
	passwordResetRequested: PropTypes.bool.isRequired,
	resendVerification: PropTypes.func.isRequired,
	verificationResent: PropTypes.bool.isRequired,
	addNotification: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

export default Login;
