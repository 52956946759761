import _ from 'lodash';

export const handleChange = (that, name, value) => {
	that.setState((prevState) => {
		const newState = _.cloneDeep(prevState);
		return _.set(newState, name, value);
	});
};

export const handleError = (that, name, error) => {
	that.setState((prevState) => ({
		errors: {
			...prevState.errors,
			[name]: error
		}
	}));
};
